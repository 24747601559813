<template>
  <div>
    <el-data-table ref="table" v-bind="tableConfig" :columns="columns">
    </el-data-table>

    <el-dialog :title="title" :visible.sync="dialogFormVisible" :close-on-press-escape="false" :close-on-click-modal="false"  :before-close="closeDialog">
      <el-form :model="form" :rules="rules" ref="ruleUser">
          <el-form-item label='主键id' prop='id' :label-width="formLabelWidth" v-if="false">
            <el-input placeholder='请输入主键id' clearable autocomplete="off" v-model='form.id' size="small"  />
          </el-form-item>
          <el-form-item label='用户ID' prop='userId' :label-width="formLabelWidth">
            <el-input placeholder='请输入用户ID' clearable autocomplete="off" v-model='form.userId' size="small"  />
          </el-form-item>
          <el-form-item label='角色ID' prop='roleId' :label-width="formLabelWidth">
            <el-input placeholder='请输入角色ID' clearable autocomplete="off" v-model='form.roleId' size="small"  />
          </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog()">取 消</el-button>
        <el-button type="primary" @click="submitUser">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { insertSysUserRole,updateSysUserRole,deleteSysUserRole,bacthDelSysUserRole } from "@/request/requests";
  import { MessageSuccess,MessageError,MessageInfo } from "@/util/util"
  export default {
    name: "user",
    data(){
      const columns = [
        {type: 'selection'},
        {prop: 'id',label: '主键id'},
        {prop: 'userId',label: '用户ID'},
        {prop: 'roleId',label: '角色ID'},
      ]
      const searchForm = [
          {
            type: 'input',
            id: 'id',
            label: '主键id',
            el: {placeholder: '请输入主键id', clearable: true},
          },
          {
            type: 'input',
            id: 'userId',
            label: '用户ID',
            el: {placeholder: '请输入用户ID', clearable: true},
          },
          {
            type: 'input',
            id: 'roleId',
            label: '角色ID',
            el: {placeholder: '请输入角色ID', clearable: true},
          },
      ]
      return {
        columns,
        tableConfig: {
          searchForm,
          url: 'sysuserrole/list',
          dataPath: 'data.list',
          totalPath: 'data.total',
          paginationSize:10,
          extraButtons: [
            {
              type: 'primary',
              text: '编辑',
              icon: 'el-icon-edit-outline',
              show:()=>{return this.hasRole('sysuserrole:update')},
              atClick: async row => {
                this.handleEdit(row)
                return false
              },
            },
            {
              type: 'primary',
              text: '删除',
              icon: 'el-icon-delete',
              show:()=>{return this.hasRole('sysuserrole:delete')},
              atClick: async row => {
                this.handleDelete(row)
                return false
              },
            },
          ],
          headerButtons: [
            {
              text: '新增',
              type: 'primary',
              show:()=>{return this.hasRole('sysuserrole:insert')},
              atClick: async () => {
                this.handleInsert()
                return false
              },
            },
            {
              text: '批量删除',
              type: 'primary',
              show:()=>{return this.hasRole('sysuserrole:batchDelete')},
              disabled: selected => selected.length < 1,
              atClick: selected => {
                let ids = selected.map(item => item.id)
                this.Batch('delete', ids)
                return false
              },
            },
          ],
          hasDelete: false,
          hasEdit: false,
          hasOperation: true,
          hasNew: false,
          operationAttrs: {
            width: '150px',
            fixed: 'right',
          },
        },
        form:this.initForm(),
        title:"",
        type:"",
        dialogFormVisible:false,
        formLabelWidth:"100px",
        rules: {
            id :[
              {required: true, message: '请输入主键id', trigger: 'blur'},],
            userId :[
              {required: true, message: '请输入用户ID', trigger: 'blur'},],
            roleId :[
              {required: true, message: '请输入角色ID', trigger: 'blur'},],
        },
      }
    },
    methods:{
        hasRole(val){
            return this.hasPerm(val)
        },
      initForm() {//初始数据
        return {
          id : "", // 主键id
          userId : "", // 用户ID
          roleId : "", // 角色ID
        }
      },
      handleEdit(row) {
        console.log(row)
        this.form = row
        this.openDialog();
        this.title = "修改信息"
        this.type = "update"
      },
      handleDelete(row) {
          this.$confirmel('此操作将永久删除该文件, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
          }).then(() => {
              this.operatorDeleteUser(row)
          }).catch(() => {
              MessageInfo("已取消删除");
          });
      },
      async operatorDeleteUser(row) {
        let res = await deleteSysUserRole(row.id)
        if (res.status == "200") {
          MessageSuccess(res.msg)
        } else {
          MessageError(res.msg)
        }
        this.$refs.table.getList()
      },
      handleInsert() {
        this.openDialog();
        this.title = "新增"
        this.type = "insert"
      },
      async Batch(type, ids) {
        if (type == "delete") {
          let res = await bacthDelSysUserRole(ids)
          if (res.status == "200") {
            MessageSuccess(res.msg)
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },
      openDialog() {
        this.dialogFormVisible = true
      },
      closeDialog() {
        this.dialogFormVisible = false
        this.form = this.initForm();
        this.$refs.ruleUser.clearValidate();
        this.$refs.table.getList()
      },
      async submitUser() {
        this.$refs.ruleUser.validate((valid) => {
          if (valid) {
            this.operatorUser()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      async operatorUser() {
        let res;
        if (this.type == "insert") {
          res = await insertSysUserRole(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        } else if (this.type == "update") {
          res = await updateSysUserRole(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },getUserInfo(){
        let deadline = sessionStorage.getItem('deadline');
        let userInfo = sessionStorage.getItem("userInfo");
        let token = sessionStorage.getItem('token');
        if(!deadline||!userInfo||!token) {
          this.removeInfo()
          MessageError("请先登录！")
          this.$router.push({ path: "/login" });
          return false
        }else{
          let now = new Date().getTime();
          if (deadline<now){
            this.removeInfo()
            MessageError("请先登录！")
            this.$router.push({ path: "/login" });
            return false
          }
        }
        return userInfo;
      },
      removeInfo(){
        sessionStorage.removeItem("userInfo");
        sessionStorage.removeItem("deadline");
        sessionStorage.removeItem("token");
      }
    },
    created() {
      let userInfo=this.getUserInfo()
      if (userInfo){
        this.tableConfig.axiosConfig={
          headers: {
            'Authorization': 'X-KILOGOD-AUTHORIZATION '+sessionStorage.getItem('token'),

          }
        }
      }
    }
  }
</script>

<style scoped>

</style>
